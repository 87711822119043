import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import daoPic1 from "@/assets/img/dao/dao_pic1.png";
import daoPic2 from "@/assets/img/dao/dao_pic2.png";
import point from "@/assets/img/dao/dao_point.png";

import "./dao.less";
import Button from "../../Components/Button/Button";
import ButtonFlat from "../../Components/Button/ButtonFlat";


const Dao = () => {
  const { t } = useTranslation();

  const daos = [
      {
          'name':'[Custom Proposal] Transfer 15,000 OPL to marketing fund & burn 15,000 OPL',
          'desc':'This proposal seeks to transfer 15,000 OPL from the DAO Treasury to the marketing wallet to be used for the OpenAs,  and other and other community incentive programs. These programs serve to increase engagement across multiple social media platforms and produce organic word-of-mouth marketing opportunities.',
      },
      {
          'name':'[Custom Proposal] Transfer 15,000 OPL to marketing fund & burn 15,000 OPL',
          'desc':'This proposal seeks to transfer 15,000 OPL from the DAO Treasury to the marketing wallet to be used for the OpenAs,  and other and other community incentive programs. These programs serve to increase engagement across multiple social media platforms and produce organic word-of-mouth marketing opportunities.',
      }
  ]

  return (
    <div className="root">
        <div className="container-dao">
            <div className="flex-container-dao">
                <div className="card-dao">
                    <img style={{width:65}} src={daoPic1}/>
                    <div>DAO</div>
                    <img style={{width:65,marginTop:60}} src={daoPic2}/>
                    <div>Discuss</div>
                </div>

                <div className="card2-dao">
                    <div style={{marginTop:10,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{fontSize:22,color:'#000',fontWeight:'bold'}}>Latest Proposals</div>
                        <div>
                            <Button style={{width:120}}>More</Button>
                            <ButtonFlat style={{width:120,marginLeft:30}}>Create</ButtonFlat>
                        </div>
                    </div>

                    <div>
                        {daos.map((item, index) => (
                            <div className="card3-dao">
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                                    <img style={{width:15}} src={point}/>
                                    <Button style={{width:100,background:'#eee',color:'#000',marginLeft:20}}>Closed</Button>
                                </div>
                                <div style={{color:'#000',fontWeight:'bold',fontSize:24,marginTop:20}}>{item.name}</div>
                                <div style={{fontSize:18,marginTop:5}}>{item.desc}</div>

                                <div className="progress-bar-container-dao">
                                    <span className="progress-left-text-dao">YES</span>
                                    <div className="progress-bar-dao" style={{ width: `${100}%` }}></div>
                                    <span className="progress-right-text-dao">100%</span>
                                </div>
                                <div className="progress-bar-container-dao">
                                    <span className="progress-left-text-dao">NO</span>
                                    <div className="progress-bar-dao" style={{ width: `${0}%` }}></div>
                                    <span className="progress-right-text-dao">0%</span>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </div>
        </div>
    </div>
  );
};

export default Dao;
