import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import pdfIcon from "@/assets/img/common/pdf_icon.png";

import "./finance.less";
import Button from "../../Components/Button/Button";
import { MiniChart } from "react-ts-tradingview-widgets";
import InputWithButton from "../../Components/Input/InputWithButton";
import ButtonFlat from "../../Components/Button/ButtonFlat";


const Finance = () => {
  const { t } = useTranslation();

  const logs = [
      {
          'name':'OpenAs financial statements PDF (2024.05)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.06)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.07)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.08)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.09)',
          'url':"",
      },
      {
          'name':'OpenAs financial statements PDF (2024.10)',
          'url':"",
      }
  ]

  return (
    <div className="root">
        <div className="container-finance">
            <div className="finance-title">{t('Finance')}</div>
            <div style={{marginTop:20,marginBottom:20}}>
                <Button style={{width:140}}>OPE</Button>
                <Button style={{width:140,background:'#eee',color:'#000',marginLeft:30}}>OPL</Button>
            </div>

            <div className="flex-container-finance">
                <div className="card-finance">
                    <MiniChart colorTheme="light" width="100%" chartOnly={false} height="100%"
                               symbol="BTCUSD"
                               trendLineColor="rgba(13,184,54,0.6)"
                                underLineColor="rgba(24,221,138,0.4)"
                                underLineBottomColor="rgba(224,240,38,0.2)"></MiniChart>
                </div>
                <div className="card2-finance">
                    <div style={{fontSize:22,color:'#000',fontWeight:'bold'}}>Buy OPE</div>
                    <div style={{marginTop:20,marginBottom:20}}>
                        <Button style={{width:100}}>BUY</Button>
                        <Button style={{width:100,background:'#eee',color:'#000',marginLeft:20}}>SELL</Button>
                    </div>
                    <div style={{marginBottom:20}}>
                        <div style={{fontSize:16,color:'#000',fontWeight:'bold',marginBottom:10,marginTop:20}}>USDT Amount</div>
                        <InputWithButton width="100%"/>
                    </div>
                    <div style={{marginBottom:10}}>
                        <div style={{fontSize:16,color:'#000',fontWeight:'bold',marginBottom:10,marginTop:20}}>
                            You will receive
                        </div>
                        <div style={{width:'100%',height:60,display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <div className="finance-amount">3000 OPE</div>
                        </div>
                    </div>
                    <Button style={{marginTop:10}}>Swap</Button>
                </div>
            </div>

            <div className="finance-card-log">
                <div style={{fontSize:22,color:'#000',fontWeight:'bold'}}>Financial Statements</div>
                <div style={{marginTop:20,paddingTop:20}}>
                    {logs.map((item, index) => (
                        <div style={{marginBottom:40,paddingLeft:20,paddingRight:20,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',fontSize:16,color:'#000'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <img src={pdfIcon} style={{width:30,marginRight:30}}/>
                                <div>{item.name}</div>
                            </div>
                            <ButtonFlat style={{width:100}}>View</ButtonFlat>
                        </div>
                    ))}
                </div>
            </div>

      </div>
    </div>
  );
};

export default Finance;
