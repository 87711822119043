import React from "react";
import { useTranslation } from "react-i18next";

import "./footer.less";

import tw from "@/assets/img/footer/tw.png";
import discord from "@/assets/img/footer/discord.png";
import me from "@/assets/img/footer/me.png";
import tel from "@/assets/img/footer/tel.png";
import gitbook from "@/assets/img/footer/gitbook.png";
import logo from "@/assets/img/header/logo.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
      <div className="footer">
          <div style={{display:'flex',flexDirection:'column'}}>
              <img src={logo} alt="" style={{width:120}} />
              <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                  <a href="#"><img src={tw} alt="" style={{width:30,marginRight:10}} /></a>
                  <a href="#"><img src={tel} alt="" style={{width:30,marginRight:10}} /></a>
                  <a href="#"> <img src={discord} alt="" style={{width:30,marginRight:10}} /></a>
              </div>
              <div style={{marginTop:20}}>
                  <p className="copyright-text">Copyright &copy; 2024 All Rights Reserved by
                      <a href="#">OpenAs</a>.
                  </p>
              </div>
          </div>
          <div style={{display:'flex',flexDirection:'row'}}>
              <div className="special-container">
                  <div className="title">Company</div>
                  <a href="#">FAQ</a>
                  <a href="#">Docs</a>
              </div>
              <div className="special-container">
                  <div className="title">Useful Links</div>
                  <a href="https://bscscan.com/">BSC Scan</a>
                  <a href="#">Roadmap</a>
                  <a href="https://pancakeswap.finance/">Pancakeswap</a>
              </div>
          </div>
      </div>
  );

  return (
      <footer className="site-footer">
          <div className="container">
              <div className="row">
                  <div className="col-sm-12 col-md-6">
                      <h6>
                          <img src={logo} alt="" style={{width:120}} />
                      </h6>
                      <div className="col-md-4 col-sm-6 col-xs-12">
                          <ul className="social-icons">
                              <li><a className="dribbble" href="#"> <img src={tw} alt="" style={{width:30}} /></a></li>
                              <li><a className="facebook" href="#"><img src={tel} alt="" style={{width:30}} /></a></li>
                              <li><a className="twitter" href="#"><img src={discord} alt="" style={{width:30}} /></a></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-xs-6 col-md-3">
                      <h6>Company</h6>
                      <ul className="footer-links">
                          <li><a href="#">FAQ</a></li>
                          <li><a href="#">Docs</a></li>
                      </ul>
                  </div>

                  <div className="col-xs-6 col-md-3">
                      <h6>Useful Links</h6>
                      <ul className="footer-links">
                          <li><a href="https://bscscan.com/">BSC Scan</a></li>
                          <li><a href="#">Roadmap</a></li>
                          <li><a href="https://pancakeswap.finance/">Pancakeswap</a></li>
                      </ul>
                  </div>
              </div>
          </div>
          <div className="container">
              <div className="row">
                  <div className="col-md-8 col-sm-6 col-xs-12">
                      <p className="copyright-text">Copyright &copy; 2024 All Rights Reserved by
                          <a href="#">OpenAs</a>.
                      </p>
                  </div>
              </div>
          </div>
      </footer>
  );
};

export default Footer;
