import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import arrow from "@/assets/img/ido/arrow.png";
import step1 from "@/assets/img/ido/step1.png";
import step2 from "@/assets/img/ido/step2.png";
import pic from "@/assets/img/ido/pic.png";
import tokenlogo from "@/assets/img/common/token.png";
import divired from "@/assets/img/ido/divired.png";

import "./ido.less";
import InputWithButton from "../../Components/Input/InputWithButton";
import Button from "../../Components/Button/Button";


const Ido = () => {
  const { t } = useTranslation();

  return (
    <div className="root">
        <div className="container-ido">
            {/* 上方宽度100%的卡片 */}
            <div className="top-card">
                <div className="top-card-container">
                    <img className="top-card-step-image" src={step1} alt="" />
                    <div className="top-card-container-text">
                        <div className="top-card-title">Start</div>
                        <div className="top-card-desc">9th May 11:00-23rd May 05:00 UTC</div>
                    </div>
                </div>
                <img className="top-card-image" src={arrow} alt="" />
                <div className="top-card-container">
                    <img className="top-card-step-image" src={step2} alt="" />
                    <div className="top-card-container-text">
                        <div className="top-card-title">End</div>
                        <div className="top-card-desc">19th May 11:00-23rd May 05:00 UTC</div>
                    </div>
                </div>
            </div>

            {/* 下方flex容器a */}
            <div className="flex-container">
                <div className="card">
                    <img src={pic} className="ido-pic"/>
                    <div style={{display:'flex',flexDirection:'row',marginTop:5,padding:20}}>
                        <img src={tokenlogo} style={{width:55,height:55}}/>
                        <div style={{marginLeft:10}}>
                            <div style={{fontSize:20,color:'#000',fontWeight:'bold'}}>Launchpad:OPE</div>
                            <div style={{fontSize:16,color:'#000'}}>Invest in 50 smart eco-cities in Cambodia, future city projects integrating life, commerce and education.</div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div style={{padding:20}}>
                        <div className="top-card-title">End Time:</div>
                    </div>
                    <div className="countdown-container">
                        <div className="countdown">
                            <div className="text-container">
                                <div className="title_countdown gradient-text">30</div>
                                <div className="desc_countdown">Day</div>
                            </div>
                        </div>
                        <img src={divired} alt="Container Image" className="countdown-img" />
                        <div className="countdown">
                            <div className="text-container">
                                <div className="title_countdown gradient-text">03</div>
                                <div className="desc_countdown">Hour</div>
                            </div>
                        </div>
                        <img src={divired} alt="Container Image" className="countdown-img" />
                        <div className="countdown">
                            <div className="text-container">
                                <div className="title_countdown gradient-text">14</div>
                                <div className="desc_countdown">Min</div>
                            </div>
                        </div>
                        <img src={divired} alt="Container Image" className="countdown-img" />
                        <div className="countdown">
                            <div className="text-container">
                                <div className="title_countdown gradient-text">55</div>
                                <div className="desc_countdown">Sec</div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{ width: `${30}%` }}></div>
                        </div>
                        <div className="progress-lable">
                            <div>1000 USDT</div>
                            <div>10002 USDT</div>
                        </div>


                    </div>

                    <div className="ido-gobal-lable">
                        <div style={{fontSize:16,color:'#000',fontWeight:'bold',marginBottom:10,marginTop:20}}>Amount</div>
                        <InputWithButton width="100%"/>
                        <Button style={{marginTop:50}}>Next</Button>
                    </div>


                </div>
            </div>
        </div>
    </div>
  );
};

export default Ido;
