import {useTranslation} from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";

export const Features = (props) => {
    const { t, i18n } = useTranslation();
    const isMobile = useIsMobile();

    const data = [
        {
            "icon": "fa fa-cart-arrow-down",
            "title": t('Features_title1'),
            "text": t('Features_desc1')
        },
        {
            "icon": "fa fa-pie-chart",
            "title": t('Features_title2'),
            "text": t('Features_desc2')
        },
        {
            "icon": "fa fa-plane",
            "title": t('Features_title3'),
            "text": t('Features_desc3')
        },
        {
            "icon": "fa fa-group",
            "title": t('Features_title4'),
            "text": t('Features_desc4')
        }
    ]


    return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>{t('Features_title')}</h2>
        </div>
        <div className='row'>
          {data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            }
        </div>
      </div>
    </div>
  )
}
