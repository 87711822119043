// Button.js
import styled from 'styled-components';

const ButtonFlat = styled.button`
  position: relative;
  background: transparent;
  color: transparent;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 28px;
  cursor: pointer;
  z-index: 1;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, rgba(224, 240, 38, 0.96), rgba(13, 184, 54, 0.96), rgba(24, 221, 138, 0.96));
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 28px;
    padding: 2px; /* 调整边框宽度 */
    background: linear-gradient(90deg, rgba(224, 240, 38, 0.96), rgba(13, 184, 54, 0.96), rgba(24, 221, 138, 0.96));
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    z-index: -1;
  }
`;

export default ButtonFlat;
