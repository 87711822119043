import {useTranslation} from "react-i18next";
import binance from "@/assets/img/home/partncrs/binance.png";
import bscscan from "@/assets/img/home/partncrs/bscscan.png";
import chainbow from "@/assets/img/home/partncrs/chainbow.png";
import coingecko from "@/assets/img/home/partncrs/coingecko.png";
import coinmarketcap from "@/assets/img/home/partncrs/coinmarketcap.png";
import daliu from "@/assets/img/home/partncrs/daliu.png";
import dimensional from "@/assets/img/home/partncrs/dimensional.png";
import linghe from "@/assets/img/home/partncrs/linghe.png";
import pancakeswap from "@/assets/img/home/partncrs/pancakeswap.png";
import wallet3 from "@/assets/img/home/partncrs/wallet3.png";
import tianji from "@/assets/img/home/partncrs/tianji.png";
import betab from "@/assets/img/home/partncrs/betab.png";
import q91 from "@/assets/img/home/partncrs/q91.png";
import gongde from "@/assets/img/home/partncrs/gongde.png";

export const Testimonials = (props) => {
    const { t, i18n } = useTranslation();


    return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>{t('Parents_title')}</h2>
        </div>
      </div>
    </div>
  )
}
