// Button.js
import styled from 'styled-components';

const Button = styled.button`
  background: linear-gradient(90deg, rgba(224, 240, 38, 0.96), rgba(13, 184, 54, 0.96), rgba(24, 221, 138, 0.96));
  border: none;
  border-radius: 28px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

export default Button;
