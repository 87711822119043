import React, { useCallback, useEffect, useState, useRef } from "react";
import { INVITER_ADDR, SELL_MANAGER, USDT_TOKEN } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { getArticleList, getNftList } from "../../requests";

import "./home.less";

import homeTopBg from "@/assets/img/home/home_top_bg.png";
import homeTopBgPc from "@/assets/img/home/home_top_bg_pc.png";
import homeLogo from "@/assets/img/home/home_logo.png";
import homeLogoCn from "@/assets/img/cnimg/homeLogoCn.png";
import jinren from "@/assets/img/home/jinren.png";
import martLogo from "@/assets/img/home/mart_logo.png";
import martTitle from "@/assets/img/home/mart_title.png";
import homeContent from "@/assets/img/home/home_content.png";
import adoptLogo from "@/assets/img/home/adopt_logo.png";
import slogan_robot from "@/assets/img/home/slogan_logo_robot.png";
import slogan_text from "@/assets/img/home/slogan_logo_slogan.png";
import adoptListTitle from "@/assets/img/home/adopt_list_title.png";
import adoptListItemLogo from "@/assets/img/home/adopt_list_item_logo.png";
import currBnb from "@/assets/img/home/curr_bnb.png";
import nftTitle from "@/assets/img/home/nft_title.png";
import colorBar from "@/assets/img/home/color_bar.png";
import whatItMira from "@/assets/img/home/What_it_MiRA.png";
import whatItMiraCn from "@/assets/img/cnimg/whatItMiraCn.png";
import missionVision from "@/assets/img/home/Mission_Vision.png";
import missionVisionCn from "@/assets/img/cnimg/missionVisionCn.png";
import More from "@/assets/img/home/More.png";
import AmbassadorProgram from "@/assets/img/home/Ambassador_Program.png";
import JointheMovement from "@/assets/img/home/Join_the_Movement.png";
import logotwo from "@/assets/img/header/logotwo.png";
import rankone from "@/assets/img/home/rankone.png";
import ranktwo from "@/assets/img/home/ranktwo.png";
import ranktherr from "@/assets/img/home/ranktherr.png";
import miraNews from "@/assets/img/home/mira_news.png";
import miraNewsCn from "@/assets/img/cnimg/miraNewsCn.png";
import clickToView from "@/assets/img/home/click_to_view.png";
import clickToViewCn from "@/assets/img/cnimg/clickToViewCn.png";
import close from "@/assets/img/home/close.png";
import binance from "@/assets/img/home/partncrs/binance.png";
import bscscan from "@/assets/img/home/partncrs/bscscan.png";
import chainbow from "@/assets/img/home/partncrs/chainbow.png";
import coingecko from "@/assets/img/home/partncrs/coingecko.png";
import coinmarketcap from "@/assets/img/home/partncrs/coinmarketcap.png";
import daliu from "@/assets/img/home/partncrs/daliu.png";
import dimensional from "@/assets/img/home/partncrs/dimensional.png";
import linghe from "@/assets/img/home/partncrs/linghe.png";
import pancakeswap from "@/assets/img/home/partncrs/pancakeswap.png";
import wallet3 from "@/assets/img/home/partncrs/wallet3.png";
import tianji from "@/assets/img/home/partncrs/tianji.png";
import betab from "@/assets/img/home/partncrs/betab.png";
import q91 from "@/assets/img/home/partncrs/q91.png";
import gongde from "@/assets/img/home/partncrs/gongde.png";
import partners from "@/assets/img/home/partners.png";
import partnersCn from "@/assets/img/home/partnersCn.png";

import useInterval from "../../hooks/useInterval";
import { getBalanceNumber, toBigString } from "../../utils/formatBalance";
import BigNumber from "bignumber.js";
import { usdtTokenContract } from "../../hooks/usdtTokenContract";
import { createPublicClient, http } from "viem";
import { arbitrum, bsc } from "wagmi/chains";
import { useAccount } from "wagmi";
import useWithdrawToken from "../../hooks/useWithdrawToken";
import useTokenApprove from "../../hooks/useTokenApprove";
import NftList from "../../Components/NftList";
import useIsMobile from "../../hooks/useIsMobile";
import Footer from "../../Components/Footer";
import { message, Tooltip } from "antd";
import { formatAddress } from "../../utils/utils";
import { getLang } from "../../utils/getLang";
import JsonData from '../data/data.json'
import {Features} from "../../Components/Landing/features";
import {About} from "../../Components/Landing/about";
import {Services} from "../../Components/Landing/services";
import {Gallery} from "../../Components/Landing/gallery";
import {Testimonials} from "../../Components/Landing/testimonials";
import {Header} from "../../Components/Header/header";


const Home = () => {
  const { t } = useTranslation();
  const API_SERVER = process.env.REACT_APP_API_SERVER;
  const isMobile = useIsMobile();
  const location = useLocation();
  // 使用URLSearchParams解析查询参数
  const query = new URLSearchParams(location.search);
  const channel = query.get("channel");
  const lang = getLang();

  useEffect(() => {

  }, []);


  // 合作伙伴列表
  const partnersList = [
    {
      logo: coingecko,
      link: "https://www.coingecko.com/",
    },
    {
      logo: coinmarketcap,
      link: "https://coinmarketcap.com/",
    },
    {
      logo: pancakeswap,
      link: "https://pancakeswap.finance/",
    },
    {
      logo: binance,
      link: "https://www.binance.com/en",
    },
    {
      logo: bscscan,
      link: "https://bscscan.com",
    },
    {
      logo: chainbow,
      link: "https://chainbow.io/",
    },
  ];

  const [landingPageData, setLandingPageData] = useState({})

  useEffect(() => {
    setLandingPageData(JsonData)
  }, []);

  return (
    <div >
      <div style={{background:'#fff'}}>
        <Header  />
        <div className="floating-card">
          <div className="data-section">
            <div className="data-item">
              <p className="data-number">$71,138,200</p>
              <p className="data-title">OPL TVL</p>
            </div>
            <div className="divider"></div>
            <div className="data-item">
              <p className="data-number">45%</p>
              <p className="data-title">OPL APY</p>
            </div>
            <div className="divider"></div>
            <div className="data-item">
              <p className="data-number">3.2%</p>
              <p className="data-title">OPE APY</p>
            </div>
          </div>
        </div>
        <Features  />
      </div>

      <About  />
      <Gallery />
      <Testimonials/>
      <div className="image-grid">
        {partnersList.map((item, index) => (
            <a key={index} href={item.link} className="image-item" target="_blank" rel="noopener noreferrer">
              <img src={item.logo} alt={`logo-${index}`} />
            </a>
        ))}
      </div>
    </div>
  );
};

export default Home;
