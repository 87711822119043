import {useTranslation} from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";
import Button from "../Button/Button";
import { useLocation, Link } from "react-router-dom";

export const About = (props) => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();

  const handleOpenPDF = () => {
    window.open(`${window.location.origin}/land1.pdf`, '_blank');
  };

  const handleOpenPDF2 = () => {
    window.open(`${window.location.origin}/land2.pdf`, '_blank');
  };

  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/about.jpg' className='img-responsive' alt='' />{' '}
          </div>
          <div style={{marginTop:50}} className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>{t('About_title')}</h2>
              <p>{t('About_desc')}</p>
            </div>
            <Button onClick={handleOpenPDF}>{t('About_btn1')}</Button>
            <Button onClick={handleOpenPDF2} style={{marginLeft:20}}>{t('About_btn2')}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
