import React from "react";
import Button from "../Button/Button";

const InputWithButton = ({ width = "300px" }) => {
    return (
        <div style={{ ...styles.container, width }}>
            <input type="text" placeholder="" style={styles.input} />
            <Button style={styles.button}>Max</Button>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "5px",
    },
    input: {
        flex: 1,
        border: "none",
        outline: "none",
        padding: "8px",
        borderRadius: "8px 0 0 8px",
        fontSize:"16px",
    },
    button: {
        marginLeft: "8px",
        padding: "8px 12px",
        border: "none",
        borderRadius: "8px",
        backgroundColor: "#007bff",
        color: "#fff",
        cursor: "pointer",
        width:'150px'
    },
};

export default InputWithButton;
