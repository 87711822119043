import ParticlesBg from "particles-bg";
import {useTranslation} from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";

export const Header = (props) => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <header id='header'>
      <div className='intro'>
        <ParticlesBg type="circle" bg={{zIndex: 0, position:"absolute", top:0}} />
        <div className='overlay'>
          <div className='container'>
          
            <div className='row'>
              {!isMobile?
                  <div className='col-md-8 col-md-offset-2 intro-text'>
                    <h1>
                      {t('Home_label1')}
                      <span></span>
                    </h1>
                    <p>{t('Home_label2')}</p>
                  </div>
                  :
                  <div className='col-md-8 col-md-offset-2 intro-text'>
                    <h1 style={{color:'#fff',fontSize:18}}>
                      {t('Home_label1')}
                      <span></span>
                    </h1>
                    <h3>{t('Home_label2')}</h3>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
