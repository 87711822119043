import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import commingsoon from "@/assets/img/common/comingsoon.png";

import "./meta.less";


const Meta = () => {
  const { t } = useTranslation();

  return (
    <div className="root">
        <div className="container">
            <img className="commingsoon" src={commingsoon}/>
        </div>
    </div>
  );
};

export default Meta;
